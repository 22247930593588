import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import MfeCover from "../images/mfe-cover.png";
import MfeNewStamp from "../images/mfe-new-stamp.png";
import MfeCollection from "../images/mfe-collection.png";
import MfeList from "../images/mfe-list.png";
import MfePiano from "../images/piano-qr-wide.jpg";
import BlastlandBlogIndex from "../images/blastland-blog-index.png";
import BlastlandContact from "../images/blastland-contact.png";
import BlastlandArticle from "../images/blastland-article.png";
import BlastlandIntro from "../images/blastland-intro.png";
import TodoCover from "../images/todo-cover.png";
import TodoAdd from "../images/todo-add.png";
import TodoHelp from "../images/todo-help.png";
import AmulProfile from "../images/amul-profile.png";
import AmulBadges from "../images/amul-badges.png";
import AmulEdit from "../images/amul-edit.png";

export default function Portfolio() {
  return (
    <Layout>
      <Helmet>
        <title>Web Portfolio | Nathan Pasko</title>
        <meta
          name="description"
          content="Selection of past work by Nathan Pasko, a designer and developer for hire specializing in front end web dev."
        />
      </Helmet>
      <section className="portfolio-section">
        <ul className="horizontal-scroll gallery">
          <li>
            <header>
              <h3 className="portfolio-header">Arts MU Live!</h3>
              <p>Virtual programs app for performing arts org</p>
            </header>
          </li>
          <li>
            <picture>
              <img
                className="shadow bezel"
                src={AmulProfile}
                height=""
                width=""
                alt=""
              />
            </picture>
          </li>

          <li>
            <picture>
              <img
                className="shadow browser"
                src={AmulBadges}
                height=""
                width=""
                alt=""
              />
            </picture>
          </li>
          <li>
            <p>
              Programs for each show are distributed and edited by admins via
              the web app. While the show is live, the app takes on a slimmed
              down dark appearance for minimal light polution. Though the
              program portions of the app are mobile-centric, patrons can log in
              from home and print programs collected in their libraries.
            </p>
          </li>
          <li>
            <picture>
              <img
                className="shadow browser"
                src={AmulEdit}
                height=""
                width=""
                alt=""
              />
            </picture>
          </li>
        </ul>
      </section>
      <section className="portfolio-section">
        <ul className="horizontal-scroll gallery">
          <li>
            <header>
              <h3 className="portfolio-header">Blastland ECM</h3>
              <p>Website and blog</p>
            </header>
          </li>
          <li>
            <picture>
              <img
                className="shadow bezel"
                src={BlastlandIntro}
                height=""
                width=""
                alt=""
              />
            </picture>
          </li>
          <li>
            <picture>
              <img
                className="shadow browser"
                src={BlastlandContact}
                height=""
                width=""
                alt=""
              />
            </picture>
          </li>
          <li>
            <picture>
              <img
                className="shadow bezel"
                src={BlastlandBlogIndex}
                height=""
                width=""
                alt=""
              />
            </picture>
          </li>
          <li>
            <picture>
              <img
                className="shadow bezel"
                src={BlastlandArticle}
                height=""
                width=""
                alt=""
              />
            </picture>
          </li>
        </ul>
      </section>
      <section className="portfolio-section">
        <ul className="horizontal-scroll gallery">
          <li>
            <header>
              <h3 className="portfolio-header">Passport</h3>
              <p>Promotional web app featuring a virtual stamp book</p>
            </header>
          </li>
          <li>
            <picture>
              <img
                className="shadow bezel"
                src={MfeCover}
                height=""
                width=""
                alt=""
              />
            </picture>
          </li>
          <li>
            <picture>
              <img
                className="shadow bezel"
                src={MfeNewStamp}
                height=""
                width=""
                alt=""
              />
            </picture>
          </li>
          <li>
            <picture>
              <img
                className="shadow bezel"
                src={MfeCollection}
                height=""
                width=""
                alt=""
              />
            </picture>
          </li>
          <li>
            <picture>
              <img
                className="shadow bezel"
                src={MfeList}
                height=""
                width=""
                alt=""
              />
            </picture>
          </li>
          <li>
            <p>
              The <strong>Passport</strong> web app invites locals to find the
              Keys for the City piano sites around town and check in using a{" "}
              <strong>QR code</strong> at each one. By checking in, users can{" "}
              <strong>collect stamps</strong> from each site. At the end of each
              season, non-profit Music for Everyone awards prizes to the most
              complete stamp collections.
            </p>
          </li>
          <li>
            <picture>
              <img src={MfePiano} height="" width="" alt="" />
            </picture>
          </li>
        </ul>
      </section>
      <section className="portfolio-section">
        <ul className="horizontal-scroll gallery">
          <li>
            <h3 className="portfolio-header">To Do</h3>
            <p>Web app</p>
          </li>
          <li>
            <p>
              This custom to-do list app helps you tackle your goals in style.
              Built with Angular, <strong>To Do</strong> is accessible from your
              web browser and saves to local storage.
            </p>
          </li>
          <li>
            <picture>
              <img
                className="shadow bezel"
                src={TodoCover}
                height=""
                width=""
              />
            </picture>
          </li>
          <li>
            <picture>
              <img
                className="shadow bezel"
                src={TodoAdd}
                height=""
                width=""
                alt=""
              />
            </picture>
          </li>
          <li>
            <picture>
              <img
                className="shadow bezel"
                src={TodoHelp}
                height=""
                width=""
                alt=""
              />
            </picture>
          </li>
        </ul>
      </section>
    </Layout>
  );
}
